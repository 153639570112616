<template>
  <div class="team_management">
    <AddTeam :getTeam="getTeam" />
    <div class="main_div2">
      <div
        class="box"
        style="height: 50px; color: white; background: #7e5493"
        v-for="item in header"
        :key="item.id"
      >
        <label for="">{{ item }} </label>
      </div>
    </div>
    <div v-if="!isLoading" class="testingScroll">
      <Container
        behaviour="contain"
        orientation="vertical"
        @drop="onDrop"
        :remove-on-drop-out="true"
        drag-class="dragging"
      >
        <Draggable v-for="(team, index) in allTeam" :key="index">
          <div class="main_div">
            <div class="box show_on_front">
              <i v-if="team.show == true" class="fal fa-check-circle"></i>
              <i v-if="team.show == false" class="fal fa-ban"></i>
            </div>
            <div class="box">
              <div
                v-if="lang == 'ka'"
                class="short_text"
                v-html="team.fullNameKA"
              ></div>
              <div v-else class="short_text" v-html="team.fullNameEN"></div>
            </div>
            <div class="box">
              <div
                v-if="lang == 'ka'"
                :ref="'descriptionKA' + index"
                class="short_text"
                style="padding-left: 20px"
              ></div>
              <div
                v-else
                :ref="'descriptionEN' + index"
                class="short_text"
                style="padding-left: 20px"
              ></div>
            </div>
            <div class="box">
              <div class="short_text" v-html="team.email"></div>
            </div>
            <div class="box">
              <div
                v-if="lang == 'ka'"
                class="short_text"
                v-html="team.positionKA"
              ></div>
              <div v-else class="short_text" v-html="team.positionEN"></div>
            </div>
            <div class="box">
              <img
                style="height: 100px; width: 100px"
                :src="`${server_url}/${team.image}`"
                alt=""
              />
            </div>
            <div class="box">
              <b-button
                @click="
                  (modalShow = !modalShow),
                    editTeam(
                      team._id,
                      team.show,
                      team.fullNameKA,
                      team.fullNameEN,
                      team.descriptionKA,
                      team.descriptionEN,
                      team.email,
                      team.positionKA,
                      team.positionEN,
                      team.image
                    )
                "
              >
                <i class="edit fal fa-pen"></i>
              </b-button>
            </div>
            <div class="box">
              <ConfirmDelete @clicktodelete="deleteTeam(team._id)" />
            </div>
          </div>
        </Draggable>
      </Container>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="5"
        :columns="8"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>

    <b-modal id="modal-xl-2" v-model="modalShow" size="xl" hide-footer>
      <div class="text_editor">
        <label for="">{{ $t("add_team.fullname") }} (KA)</label>
        <b-form-input v-model="update.fullNameKA"></b-form-input>
        <label for="">{{ $t("add_team.fullname") }} (EN)</label>
        <b-form-input v-model="update.fullNameEN"></b-form-input>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("admin.description") }} (KA)</label>
        <vue-editor v-model="update.descriptionKA"></vue-editor>
        <label for="">{{ $t("admin.description") }} (EN)</label>
        <vue-editor v-model="update.descriptionEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("admin.email") }}</label>
        <vue-editor v-model="update.email"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("add_team.position") }} (KA)</label>
        <vue-editor v-model="update.positionKA"></vue-editor>
        <label for="">{{ $t("add_team.position") }} (EN)</label>
        <vue-editor v-model="update.positionEN"></vue-editor>
      </div>
      <div>
        <label for="">{{ $t("admin.activate_on_front") }}</label>
      </div>
      <input class="checkbox" type="checkbox" v-model="update.show" />
      <div>
        <img
          style="height: 100px; width: 150px"
          :src="`${server_url}/${update.image}`"
        />
      </div>
      <input
        type="file"
        style="margin: 10px 0"
        accept="image/jpg, image/png,
      image/jpeg"
        class="input"
        @change="previewFiles($event)"
      />
      <button
        type="submit"
        class="submit btn btn-primary"
        @click="UpdateTeam()"
      >
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  const result = [...arr];
  let itemToAdd = payload;
  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }
  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }
  return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};

import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import { Container, Draggable } from "vue-smooth-dnd";
import ConfirmDelete from "../../../components/Confirm/DeleteConfirm.vue";
import Swal from "sweetalert2";

import AddTeam from "../AddComponents/AddTeam.vue";
import spinner from "../../spinner/Spinner.vue";

export default {
  name: "getTeam",
  components: {
    spinner,
    AddTeam,
    VueEditor,
    Container,
    Draggable,
    ConfirmDelete,
  },
  data() {
    return {
      modalShow: false,
      header: [
        this.$t("admin.active"),
        this.$t("admin.title"),
        this.$t("admin.description"),
        this.$t("admin.email"),
        this.$t("add_team.position"),
        this.$t("admin.image"),
        this.$t("admin.edit"),
        this.$t("admin.delete"),
      ],
      allTeam: [],
      checkedItems: [],
      active_id: "",
      update: {
        show: false,
        fullNameKA: "",
        fullNameEN: "",
        descriptionKA: "",
        descriptionEN: "",
        email: "",
        positionKA: "",
        positionEN: "",
        image: null,
      },
      server_url: env.server_url,
      isLoading: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getTeam();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    onDrop(dropResult) {
      this.allTeam = applyDrag(this.allTeam, dropResult);
      axios.post(`${env.host}/edit/full/team`, this.allTeam).then((res) => {
        console.log(res);
      });
    },
    getTeam() {
      this.isLoading = true;
      axios
        .get(`${env.host}/get/all/team/${null}/${null}/${null}`)
        .then((res) => {
          this.isLoading = false;
          res.data.item.map((team, index) => {
            this.$nextTick(() => {
              this.$refs["descriptionKA" + index].forEach((element) => {
                element.insertAdjacentHTML("beforeend", team.descriptionKA);
              });
            });
            this.$nextTick(() => {
              this.$refs["descriptionEN" + index].forEach((element) => {
                element.insertAdjacentHTML("beforeend", team.descriptionKA);
              });
            });
          });
          this.allTeam = res.data.item;
        });
    },
    previewFiles(e) {
      const image = e.target.files[0];
      this.update.image = image;
    },
    editTeam(
      id,
      show,
      fullNameKA,
      fullNameEN,
      descriptionKA,
      descriptionEN,
      email,
      positionKA,
      positionEN,
      image
    ) {
      this.active_id = id;
      this.update.show = show;
      this.update.fullNameKA = fullNameKA;
      this.update.fullNameEN = fullNameEN;
      this.update.descriptionKA = descriptionKA;
      this.update.descriptionEN = descriptionEN;
      this.update.email = email;
      this.update.positionKA = positionKA;
      this.update.positionEN = positionEN;
      this.update.image = image;
    },
    UpdateTeam() {
      this.isLoading = true;
      if (typeof this.update.image != "object") this.update.image = null;
      const formData = new FormData();
      formData.append("image", this.update.image);
      formData.append("show", this.update.show);
      formData.append("fullNameKA", this.update.fullNameKA);
      formData.append("fullNameEN", this.update.fullNameEN);
      formData.append("positionKA", this.update.positionKA);
      formData.append("positionEN", this.update.positionEN);
      formData.append("email", this.update.email);
      formData.append("descriptionEN", this.update.descriptionEN);
      formData.append("descriptionKA", this.update.descriptionKA);

      axios
        .post(`${env.host}/edit/teammate/${this.active_id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getTeam();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteTeam(id) {
      this.isLoading = true;
      axios.delete(`${env.host}/delete/teammate/${id}`).then(() => {
        Swal.fire({
          icon: "success",
          text: this.$t("success.success_delete "),
        });
        this.isLoading = false;
        this.getTeam();
      });
    },
  },
};
</script>

<style scoped>
.short_text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.testingScroll {
  width: 100% !important;
  min-height: calc(100vh - 240px);

  max-height: calc(100vh - 222px) !important;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}

.text_editor label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
.team_management {
  padding: 5px 10px 10px 10px;
  width: 100%;
  /* overflow: hidden; */
}
.box {
  width: 100%;
  padding: 5px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 90px;
  border: 1px solid #cccccc;
}
.main_div {
  width: 100%;
  background: #f2f5fa;
  display: flex;
  margin-bottom: 20px;
  border-radius: 0px;
  overflow: hidden;
  float: left;
  /* overflow-x: scroll; */
}
.main_div2 {
  width: 100%;
  background: #f2f5fa;
  display: flex;
  margin-bottom: 20px;
  border-radius: 0px;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: #7e5493;
  border-radius: 0px;
}
.show_on_front {
  font-size: 30px;
}
::v-deep .list-group-item {
  width: 400px !important;
}

button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.delete {
  background: #f02849;
  border-radius: 0px;
}
.delete i,
.edit i {
  color: white;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
.input {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  height: 40px;
  padding-left: 10px;
}
</style>
