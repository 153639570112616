<template>
  <div class="team">
    <b-button @click="modalShow = !modalShow">{{
      $t("add_team.add_team")
    }}</b-button>
    <b-modal id="modal-xl" v-model="modalShow" size="xl" hide-footer>
      <form @submit.prevent="addTeam()">
        <div class="text_editor">
          <label for="">{{ $t("add_team.fullname") }} (KA)</label>
          <b-form-input v-model="form.fullNameKA"></b-form-input>
          <label for="">{{ $t("add_team.fullname") }} (EN)</label>
          <b-form-input v-model="form.fullNameEN"></b-form-input>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("admin.description") }} (KA)</label>
          <vue-editor v-model="form.descriptionKA"></vue-editor>
          <label for="">{{ $t("admin.description") }} (EN)</label>
          <vue-editor v-model="form.descriptionEN"></vue-editor>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("admin.email") }}</label>
          <vue-editor v-model="form.email"></vue-editor>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("add_team.position") }} (KA)</label>
          <vue-editor v-model="form.positionKA"></vue-editor>
          <label for="">{{ $t("add_team.position") }} (EN)</label>
          <vue-editor v-model="form.positionEN"></vue-editor>
        </div>
        <div>
          <label for="">{{ $t("admin.activate_on_front") }}</label>
        </div>
        <input class="checkbox" type="checkbox" v-model="form.show" />
        <input
          type="file"
          style="margin: 10px 0"
          accept="image/jpg, image/png, image/jpeg"
          class="input"
          required
          @change="previewFiles($event)"
        />
        <button type="submit" class="submit btn btn-primary">
          <span v-show="!isLoading">{{ $t("admin.add") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  components: {
    VueEditor,
    spinner,
  },
  props: {
    getTeam: { type: Function },
  },
  name: "login",
  data() {
    return {
      modalShow: false,
      form: {
        show: false,
        fullNameKA: "",
        fullNameEN: "",
        descriptionKA: "",
        descriptionEN: "",
        email: "",
        positionKA: "",
        positionEN: "",
        image: null,
      },
      isLoading: false,
    };
  },
  methods: {
    previewFiles(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.form.image = image;
    },
    addTeam() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("show", this.form.show);
      formData.append("image", this.form.image);
      formData.append("fullNameKA", this.form.fullNameKA);
      formData.append("fullNameEN", this.form.fullNameEN);
      formData.append("positionKA", this.form.positionKA);
      formData.append("positionEN", this.form.positionEN);
      formData.append("email", this.form.email);
      formData.append("descriptionEN", this.form.descriptionEN);
      formData.append("descriptionKA", this.form.descriptionKA);
      axios
        .post(`${env.host}/add/teammate`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_add"),
          });
          this.isLoading = false;
          this.modalShow = false;
          this.emptyValues();
          setTimeout(() => {
            this.getTeam();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    emptyValues() {
      this.form.show = false;
      this.form.fullNameKA = "";
      this.form.fullNameEN = "";
      this.form.descriptionKA = "";
      this.form.descriptionEN = "";
      this.form.email = "";
      this.form.positionKA = "";
      this.form.positionEN = "";
      this.form.image = null;
    },
  },
};
</script>

<style scoped>
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
label {
  margin-top: 20px;
}
.team {
  margin-bottom: 10px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
.input {
  border-radius: 0px;

  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  height: 40px;
  padding-left: 10px;
}
</style>
